$(()->

  $('img').unveil verge.viewportH()

  $(window).scroll(()->
    percentage = $(this).scrollTop() / ($(document).height() - $(this).height()) * 100

    if percentage > 55
      $('.back-to-top').addClass('is-active')
    else
      $('.back-to-top').removeClass('is-active')
  )

  $(window).on('resize load'
    ->
      windowWidth = window.innerWidth
      boxinnerWidth = $('.box-inner').innerWidth()
      gradeWidth = (windowWidth - boxinnerWidth) / 2 * 0.9
      $('.box-title .grade').css('width',gradeWidth)
      $('.box-title .grade').css('visibility','visible')

      return
  )

  $(document).ready(()->
    lists = $('nav li')
    self = $(this)
    # 選択済み要素の場合終了
    if self.hasClass('active')
      return
    # エフェクト用にindexを保持
    from_idx = lists.filter('.active').removeClass('active').index()
    self.addClass 'active'
    # アニメーションを開始
    $('.gr-border').addClass 'gr-anime'
    $('.gr-border02').addClass 'gr-anime02'

    # アニメーションが終わったらアニメーション用のクラスを外す
    $('.gr-border').each (index, element) ->
      $(this).bind 'animationend webkitAnimationEnd', ->
        $(this).removeClass 'gr-anime'
        return
    $('.gr-border02').each (index, element) ->
      $(this).bind 'animationend webkitAnimationEnd', ->
        $(this).removeClass 'gr-anime02'
        return
      return
  )

)